<template>
  <div v-if="checkedCampaign" class="success root content-wrapper">
    <div class="banner text-center mb-3">
      <img
        v-lazy="require('@/assets/ssclick/banner-estxfsr-free.png')"
        width="100%"
      />
    </div>

    <div class="description">
      <b>รายละเอียดกิจกรรม</b>
      <p>
        เพียงสแกน คิวอาร์โค้ด บนโต๊ะอาหาร และกรอกเบอร์โทรศัพท์(เพียงครั้งเดียว)
        เพื่อลงทะเบียน จากนั้นกดปุ่มลุ้นรางวัล เพื่อรับฟรี เอสโคล่า ขนาด 180 มล.
        1 กระป๋อง (จำกัดสิทธิ์ 1 คน/ร้าน/วัน) เริ่มรายการ 17 มี.ค. 2568 - 16
        เม.ย. 2568 เท่านั้น
      </p>
      <p>&nbsp;</p>
      <p>
        1. ผู้ร่วมสนุกมีสิทธิได้รับรางวัลไม่จำกัดจำนวนครั้งตลอดรายการ
        แต่จำกัดสิทธิ์ 1 คน/ร้าน/วัน
      </p>
      <p>
        2. ติดต่อรับของรางวัลกับร้านอาหารที่ซื้อสินค้าเอส และสแกน คิวอาร์โค้ด
        ในวันเดียวกันกับที่ได้รับรางวัลเท่านั้น
      </p>
      <p>3. ของรางวัลไม่สามารถแลกเปลี่ยนเป็นเงินสดหรือของรางวัลอื่นได้</p>
      <p>
        4. บริษัทฯ ขอสงวนสิทธิที่จะปฏิเสธการแจกรางวัล
        หรือยกเลิกสิทธิของผู้ร่วมสนุก
        หากมีการตรวจสอบพบว่าผู้ร่วมรายการทำการทุจริต
        หรือส่อเจตนาทุจริตในการร่วมสนุก
        หรือทำผิดกฎที่ประกาศไว้ในเงื่อนไขและกติกาของกิจกรรม
        และจะไม่มีการชดเชยคืนให้แก่ผู้เข้าร่วมกิจกรรมไม่ว่ารูปแบบใดๆทั้งสิ้น
      </p>
      <p>
        5. การตัดสินของคณะกรรมการบริษัทฯ
        ถือเป็นสิทธิ์ขาดและเป็นที่สิ้นสุดทุกกรณี
      </p>
      <div style="height: 36px" />
    </div>

    <button
      v-on:click="ok()"
      style="width: 80%"
      class="btn btn-primary mx-auto mb-4 fixed-bottom"
      data-tag="ok"
      :disabled="overQuota == true && buttonlock"
    >
      ลุ้นรางวัล
    </button>

    <b-modal id="ssclickoverquota" hide-header hide-footer centered>
      <div class="text-center py-4">
        <div class="title">ไม่ต้องเสียใจนะ<br />พรุ่งนี้มาลองกันใหม่</div>
        <div class="info px-4">
          <span v-if="overUserQuota">วันนี้คุณใช้สิทธิ์แล้ว</span>
          <span v-else>วันนี้ครบจำนวนสิทธิ์แล้ว</span>
          <br />
          แต่ถ้าอยากเติมความซ่า<br />สั่ง "เอส" กับพนักงานร้านได้เลย
        </div>
        <button
          v-on:click="close()"
          style="width: 80%"
          class="btn btn-primary mt-4"
          data-tag="close"
        >
          ตกลง
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ssclick-register-success",
  props: ["shopcode"],
  data() {
    return {
      overQuota: false,
      overUserQuota: false,
      buttonlock: false,
      checkedCampaign: false,
    };
  },
  computed: {},
  methods: {
    ok() {
      if (!this.buttonlock) {
        this.fetchJoinCampaign();
      }
    },
    close() {
      window.liff.closeWindow();
    },
    async fetchCampaign() {
      window.loading(true);
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/../ssclick/shops/${this.shopcode}/campaign`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Line-Account-Id": this.$userLine?.profile?.userId,
          },
        }
      );
      if (res.status != 200) {
        // this.$router.replace('/ssclick/expired');
        // return;
      }

      this.checkedCampaign = true;
    },
    async fetchJoinCampaign() {
      window.loading(true);
      this.buttonlock = true;
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/../ssclick/campaign/join`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Line-Account-Id": this.$userLine?.profile?.userId,
          },
          body: JSON.stringify({
            ShopCode: this.shopcode,
          }),
        }
      );
      let data = await res.json();

      switch (res.status) {
        case 200: {
          if (data.getReward) {
            // for premium reward
            // this.$router.replace(
            //   "/ssclick/congrats/" + data.refCode + "?premium_reward=1"
            // );
            this.$router.replace("/ssclick/congrats/" + data.refCode);
          } else {
            // this.$router.replace("/ssclick/congrats/" + data.refCode);
            this.$router.replace("/ssclick/sorry");
          }

          return;
        }
        case 409:
          console.log("case 409");
          this.overUserQuota = data === "over_user_quota";
          this.overQuota = true;
          this.$bvModal.show("ssclickoverquota");
          break;
        case 500:
          this.Swal.fire(
            "เกิดข้อผิดพลาด",
            "กรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่",
            "error"
          );
          break;

        default: {
          console.log("no case");
          throw "no case";
        }
      }
      this.buttonlock = false;
      window.loading(false);
    },
  },
  mounted() {
    this.fetchCampaign();
    window.loading(false);
  },
};
</script>

<style scoped>
.banner {
  font-size: 25px;
  font-weight: bold;
  border: 1px solid #888;
}
.title {
  font-size: 25px;
  font-weight: bold;
}
.description {
  font-size: 16px;
  word-wrap: break-word;
}
.content-wrapper {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  display: inline-table;
}
</style>
