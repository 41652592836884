<template>
  <div class="success root content-wrapper">
    <div class="container text-center">
      <!-- <img class="my-5" alt="logo" src="@/assets/check-mark-1.svg" /> -->
      <div>
        <div v-if="$route.query.premium_reward">
          <img src="@/assets/icon/ssclick-premium-reward.png" height="50%" width="65%" />
        </div>
        <div v-else>
          <img src="@/assets/icon/ssclick-est-can-cola-upside-down.png" height="50%" width="65%" />
        </div>
      </div>
      <div class="title mt-3 ">
        ยินดีด้วย!
        <br />
        <div v-if="$route.query.premium_reward">
          คุณได้รับ กระเป๋า เอส สุดคูล ไม่สามารถเลือกลายได้
        </div>
        <div v-else>
          คุณได้ดื่ม "เอส" ฟรี
        </div>
      </div>
      <div>(โปรดติดต่อร้านค้า ภายในเวลาที่กำหนด)</div>
      <div class="refcode mt-3 ">
        Ref Code:
        <br />
        {{ refcode }}
      </div>
      <div class="timer my-3 ">เหลือเวลา {{ min }}:{{ sec }} นาที</div>

      <button
        v-on:click="ok()"
        style="width: 80%"
        class="btn btn-primary "
        data-tag="ok"
      >
        แลก
      </button>

      <b-modal id="ssclickredeem" hide-header hide-footer centered>
        <div class="text-center p-4">
          <div class="mt-1">
            <img
              src="@/assets/icon/ssclick-success.png"
              height="50%"
              width="50%"
            />
          </div>
          <div class="title">แลกรางวัลสำเร็จ</div>
          <div class="info px-4">
            พรุ่งนี้มาเล่นกันอีกนะ<br />แต่ถ้ายังซ่าไม่สุด<br />สั่ง "เอส"
            เพิ่มได้เลยน้า
          </div>
          <button
            v-on:click="close()"
            style="width: 80%"
            class="btn btn-primary mt-4"
            data-tag="close"
          >
            ตกลง
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ssclick-congrats",
  props: ["refcode"],
  data() {
    return {
      timeout: 900,
    };
  },
  computed: {
    sec() {
      const s = this.timeout % 60;
      return s.toString().padStart(2, "0");
    },
    min() {
      const s = parseInt(this.timeout / 60);
      return s.toString().padStart(2, "0");
    },
  },
  methods: {
    ok() {
      clearInterval(this.setTimeout);
      this.$bvModal.show("ssclickredeem");
    },
    close() {
      window.liff.closeWindow();
    },
  },
  mounted() {
    window.loading(false);

    this.setTimeout = setInterval(() => {
      this.timeout -= 1;
    }, 1000);
  },
  watch: {
    // change stage when timeout
    timeout(val) {
      if (val <= 0) {
        clearInterval(this.setTimeout);
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 25px;
  font-weight: bold;
}
.info {
  font-size: 20px;
}
.refcode {
  font-size: 20px;
}
.timer {
  font-size: 26px;
}
.content-wrapper {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  flex: 1;
  display: inline-table;
}
</style>
